/* iOS Cordova Tweak */
.device-cordova.device-ios {
  height: 100vh;
}

/* Custom color theme properties */
:root {
  --f7-theme-color: #00c48b;
  --f7-theme-color-rgb: 0, 196, 139;
  --f7-theme-color-shade: #009b6e;
  --f7-theme-color-tint: #00eda8;
}

/* Your app custom styles here */